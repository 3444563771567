import React, { useEffect, useRef } from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Slider from "../components/slider"
import Form from "../components/form"
import Testimonials from "../components/testimonials"
import WhyChooseUs from "../components/whyChooseUs"
import { FaQuoteLeft } from "react-icons/fa"
import gsap from "gsap"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Window Cleaning Services in Essex" />
    <Slider />
    <div className="col-left bg-sjh-pale hidden lg:block"></div>
    <div className="grid grid-cols-12 bg-white">
      <div className="col-span-12 lg:col-span-5 px-4 py-6 lg:p-12 bg-sjh-pale">
        <h2 className="uppercase text-3xl mb-2">Get your windows cleaned</h2>
        <p className="mb-4">
          Leave your name and number and we will get in touch with a free
          cleaning quote.
        </p>
        <Form name="Home" />
      </div>
      <div className="col-span-12 lg:col-span-7 py-12 md:p-12 md:pl-8 flex flex-col justify-between border-sjh-primary border-b-4 ml-4">
        <div>
          <h2 className="text-4xl mb-3 font-bold">
            A WINDOW CLEANER YOU CAN TRUST
          </h2>
          <p className="text-xl mb-3">
            SJH Cleaning is a family run window cleaning business established in
            2014, based in Wickford, Essex.
          </p>
          <p className="mb-3">
            For both the domestic & commercial customer, we offer a wide range
            of cleaning services. We clean windows, gutters, solar panels,
            conservatories, roofs, soffit & fascias.
          </p>
          <p className="mb-3">
            We cover the following areas: Wickford, South Woodham Ferrers,
            Benfleet, Hadleigh, Rayleigh, Hockley, Rochford, Southend,
            Hanningfields and Chelmsford.
          </p>
          <p className="mb-3">
            Contact us today for a free no obligation quote on cleaning services
            for your property.
          </p>
        </div>
        <blockquote className="text-right md:ml-16 mt-8 pl-10 md:pl-12 relative text-xl font-bold">
          <FaQuoteLeft className="absolute opacity-50 top-0 left-0 text-4xl" />
          THROUGHOUT ESSEX, WE HAVE ESTABLISHED A REPUTATION FOR PROVIDING AN
          EXCEPTIONAL CLEANING SERVICE, AT A PRICE THAT'S&nbsp;AFFORDABLE.
        </blockquote>
      </div>
    </div>
    <div className="col-right bg-white hidden lg:block z-10"></div>
    <ServiceSection data={data} />
    <section className="pb-12">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-2">
        <Testimonials />
        <WhyChooseUs />
      </div>
    </section>
  </Layout>
)

export default IndexPage

const ServiceSection = ({ data }) => {
  let services = useRef([])
  services.current = []
  const addToRefs = el => {
    if (el && !services.current.includes(el)) {
      services.current.push(el)
    }
  }
  useEffect(() => {
    services.current.forEach(service => {
      gsap.from(service, {
        scrollTrigger: {
          trigger: service,
          start: "top bottom",
          end: "bottom bottom",
        },
        y: 100,
        opacity: 0,
      })
    })
  }, [])
  return (
    <section className="py-12 uppercase">
      <h2 className="mb-4 text-4xl">Services</h2>
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8">
        {data.services.edges.map(({ node }, i) => {
          return (
            <div
              ref={addToRefs}
              key={node.slug}
              className="flex flex-col group"
            >
              <Link to={`/services/${node.slug}`}>
                <div
                  className="w-full rounded-sjh mb-4 transform group-hover:scale-105 group-hover:shadow-lg transition-transform"
                  style={{
                    height: 0,
                    paddingTop: "56%",
                    background: `center / cover no-repeat url(${node.featuredImage.fluid.src})`,
                  }}
                />
              </Link>
              <Link to={`/services/${node.slug}`}>
                <h3 className="text-xl transform transition-transform group-hover:text-sjh-primary">
                  {node.title}
                </h3>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const query = graphql`
  {
    services: allContentfulService {
      edges {
        node {
          title
          slug
          featuredImage {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
