import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SlickSlider from "react-slick"

const Slider = () => {
  const data = useStaticQuery(graphql`
    {
      slider: contentfulSlider {
        images {
          description
          fluid(quality: 100, maxWidth: 1600, maxHeight: 900) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  `)
  const { images } = data.slider
  const settings = { infinite: true, autoplay: true, autoplaySpeed: 4000 }
  return (
    <div className="breakout max-h-lg overflow-hidden z-0">
      <SlickSlider {...settings} className="object-cover object-center">
        {images.map(({ fluid, description }) => (
          <Img
            alt={description}
            key={fluid.src}
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={fluid}
          />
        ))}
      </SlickSlider>
    </div>
  )
}

export default Slider
