import React from "react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import SlickSlider from "react-slick"
import { FaChevronRight, FaChevronLeft } from "react-icons/fa"
import { useStaticQuery, graphql } from "gatsby"

const Testimonials = () => {
  const settings = {
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const data = useStaticQuery(graphql`
    {
      testimonials: allContentfulTestimonial {
        nodes {
          quote {
            quote
          }
          author
        }
      }
    }
  `)
  return (
    <div className="flex items-center w-full">
      <div className="bg-sjh-pale p-12 text-center rounded-sjh w-full">
        <h2 className="text-3xl uppercase mb-4">Testimonials</h2>
        <SlickSlider {...settings}>
          {data.testimonials.nodes.map(({ quote, author }) => {
            return (
              <div className="px-5" key={author}>
                <p className="mb-4 text-lg">&ldquo;{quote.quote}&rdquo;</p>
                <p className="text-lg font-bold">- {author}</p>
              </div>
            )
          })}
        </SlickSlider>
      </div>
    </div>
  )
}

export default Testimonials

const NextArrow = props => {
  const { className, style, onClick } = props
  return (
    <FaChevronRight
      className={className}
      style={{ ...style, color: "#05535B" }}
      onClick={onClick}
    />
  )
}
const PrevArrow = props => {
  const { className, style, onClick } = props
  return (
    <FaChevronLeft
      className={className}
      style={{ ...style, color: "#05535B" }}
      onClick={onClick}
    />
  )
}
